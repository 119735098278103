/* WARNING: Auto-generated by the import-fonts script. Changes here will be overwritten. */

@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Bold/open-sans-bold.woff2") format("woff2"),
    url("./OpenSans-Bold/open-sans-bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-SemiBold/open-sans-semi-bold.woff2") format("woff2"),
    url("./OpenSans-SemiBold/open-sans-semi-bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Open Sans";
  src: url("./OpenSans-Regular/open-sans-regular.woff2") format("woff2"),
    url("./OpenSans-Regular/open-sans-regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}
